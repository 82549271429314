// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-partnership-js": () => import("./../../../src/components/Partnership.js" /* webpackChunkName: "component---src-components-partnership-js" */),
  "component---src-pages-event-js": () => import("./../../../src/pages/event.js" /* webpackChunkName: "component---src-pages-event-js" */),
  "component---src-pages-form-js": () => import("./../../../src/pages/form.js" /* webpackChunkName: "component---src-pages-form-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pro-js": () => import("./../../../src/pages/pro.js" /* webpackChunkName: "component---src-pages-pro-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

